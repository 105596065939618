<template>
  <div class="right-nav cust-home-nav">    
    <div class="switch-lang" v-if="languageArray.length > 1"> 
      <div class="current-lang">
        <!-- <img class="lang-flag" :src="'https://unpkg.com/language-icons@0.3.0/icons/'+$store.state.lang.locale+'.svg'"/> -->
        <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" version="1.1" id="mdi-google-translate" width="24" height="24" viewBox="0 0 24 24"><path d="M20,5H10.88L10,2H4A2,2 0 0,0 2,4V17A2,2 0 0,0 4,19H11L12,22H20A2,2 0 0,0 22,20V7A2,2 0 0,0 20,5M7.17,14.59A4.09,4.09 0 0,1 3.08,10.5A4.09,4.09 0 0,1 7.17,6.41C8.21,6.41 9.16,6.78 9.91,7.5L10,7.54L8.75,8.72L8.69,8.67C8.4,8.4 7.91,8.08 7.17,8.08C5.86,8.08 4.79,9.17 4.79,10.5C4.79,11.83 5.86,12.92 7.17,12.92C8.54,12.92 9.13,12.05 9.29,11.46H7.08V9.91H11.03L11.04,10C11.08,10.19 11.09,10.38 11.09,10.59C11.09,12.94 9.5,14.59 7.17,14.59M13.2,12.88C13.53,13.5 13.94,14.06 14.39,14.58L13.85,15.11L13.2,12.88M13.97,12.12H13L12.67,11.08H16.66C16.66,11.08 16.32,12.39 15.1,13.82C14.58,13.2 14.21,12.59 13.97,12.12M21,20A1,1 0 0,1 20,21H13L15,19L14.19,16.23L15.11,15.31L17.79,18L18.5,17.27L15.81,14.59C16.71,13.56 17.41,12.34 17.73,11.08H19V10.04H15.36V9H14.32V10.04H12.36L11.18,6H20A1,1 0 0,1 21,7V20Z" /></svg>
        <p class="lang-text">{{$store.state.lang.locale}}</p>
        <!-- <i class="fa fa-angle-down" aria-hidden="true" ></i> -->
      </div>
      <div class="lang-dropdown">
        <div class="selecting-lang" @click="swichLanguage(lang.value)" v-for="lang in languageArray" :key="lang.value">
          <!-- <img class="lang-flag" :src="'https://unpkg.com/language-icons@0.3.0/icons/'+lang.value+'.svg'"/> -->
          <p class="lang-text">{{lang.text}}</p>
          <i class="fa fa-check" aria-hidden="true" v-if="lang.value == $store.state.lang.locale"></i>
        </div>
      </div>
    </div>
    <form class="form-inline menu-links my-2 my-lg-0">
      <div class="search-cart d-flex align-items-center">
        <div class="form-group has-search mb-0 mr-4" v-for="link in links" :key="link.title">
          <router-link :to="{ name: link.url }" class="nav-lnk d-flex align-items-center">
            <span :class="link.icon"></span>
            <span class="mbl-crt-cart cartCount" v-if="link.cartItemCount">{{cart.reduce((a, c) => (a + c.quantity),0)}}</span>
            <span class="lnk-text">{{link.title}}<span class="cartCount" v-if="link.cartItemCount">{{cart.reduce((a, c) => (a + c.quantity),0)}}</span></span>
          </router-link>
        </div>
      </div>
    </form>
  </div>
</template>


<script>
import { mapGetters } from "vuex";
import { getLanguageText } from '../helper/helper';
export default {
  data() {
    return {
      links: [
        {
          title: this.$t("Search"),
          icon: "fas fa-search",
          url:'search',
        },
        {
          title: this.$t("Cart"),
          icon: "fas fa-shopping-cart",
          cartItemCount : '0',
          url:'review-cart',
        },
      ],
      languageArray: [],
    };
  },
  computed:{
    ...mapGetters("product", ["cart"])
  },

  mounted() {
    setTimeout(() => {
      if(this.$auth.setting ) {
          this.languageArray = [       
            { 'value' : this.$auth.setting.primary_lang , text: getLanguageText(this.$auth.setting.primary_lang) },
          ];
          if (this.$auth.setting.secondary_lang) {
            this.languageArray.push({'value' : this.$auth.setting.secondary_lang, text: getLanguageText(this.$auth.setting.secondary_lang)});
          }
      }
    },2000);    
  },
  methods: {
    getLanguageText,
    swichLanguage(lang){
      localStorage.setItem('localelang', lang);
      this.$store.dispatch('lang/changeLangLocale', lang);
      window.location.reload();
    },
  }
};
</script>