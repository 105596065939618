var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"collapse navbar-collapse",attrs:{"id":"navbarSupportedContent"}},[_vm._m(0),_c('ul',{staticClass:"navbar-nav mr-auto"},[(_vm.$auth.user)?_c('div',{staticClass:"profile"},[_c('div',{staticClass:"detail-cls-lnk"},[_c('h5',[_vm._v(_vm._s(_vm.$t('Welcome', { msg: _vm.$auth.user.user_name }))+"!")]),_c('p',[_vm._v(_vm._s(_vm.$auth.user.mobile_number))])]),_c('div',{staticClass:"edit-cls-lnk"},[(_vm.$auth.user)?_c('router-link',{attrs:{"to":"/profile"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var href = ref.href;
var navigate = ref.navigate;
var isActive = ref.isActive;
return [_c('li',{class:[ isActive ? 'nav-item active':'nav-item' ]},[_c('a',{staticClass:"nav-link",attrs:{"href":href},on:{"click":navigate}},[_vm._v(" "+_vm._s(_vm.$t('My profile'))+" ")])])]}}],null,false,1851029262)}):_vm._e()],1)]):_vm._e(),(!_vm.$auth.user)?_c('div',{staticClass:"guest"},[_c('div',{staticClass:"guest-cls"},[_c('h6',[_vm._v(_vm._s(_vm.$t('Welcome Guest'))+" !")])]),_c('div',{staticClass:"login-cls-lnk"},[(!_vm.$auth.user)?_c('router-link',{attrs:{"to":"/sign-in"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var href = ref.href;
var navigate = ref.navigate;
var isActive = ref.isActive;
return [_c('li',{class:[ isActive ? 'nav-item active':'nav-item' ]},[_c('a',{staticClass:"nav-link",attrs:{"href":href},on:{"click":navigate}},[_vm._v(" "+_vm._s(_vm.$t('Sign In'))+" ")])])]}}],null,false,1389913541)}):_vm._e()],1)]):_vm._e(),_c('router-link',{attrs:{"to":"/home"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var href = ref.href;
var navigate = ref.navigate;
var isActive = ref.isActive;
return [_c('li',{class:[ isActive ? 'nav-item active':'nav-item' ]},[_c('a',{staticClass:"nav-link",attrs:{"href":href},on:{"click":navigate}},[_vm._v(" "+_vm._s(_vm.$t('Home'))+" ")])])]}}])}),(_vm.$auth.setting && (_vm.$auth.setting.product_type && _vm.$auth.setting.product_type.id == 2 && _vm.$auth.setting.store_selection == 0))?_c('router-link',{attrs:{"to":"/details"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var href = ref.href;
var navigate = ref.navigate;
var isActive = ref.isActive;
return [_c('li',{class:[ isActive ? 'nav-item active':'nav-item' ]},[_c('a',{staticClass:"nav-link",attrs:{"href":href},on:{"click":navigate}},[_vm._v(" "+_vm._s(_vm.$t('About Us'))+" ")])])]}}],null,false,633130682)}):_vm._e(),(_vm.$auth.user)?_c('router-link',{attrs:{"to":"/address"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var href = ref.href;
var navigate = ref.navigate;
var isActive = ref.isActive;
return [_c('li',{class:[ isActive ? 'nav-item active':'nav-item' ]},[_c('a',{staticClass:"nav-link",attrs:{"href":href},on:{"click":navigate}},[_vm._v(" "+_vm._s(_vm.$t('Delivery Address'))+" ")])])]}}],null,false,2379088571)}):_vm._e(),(_vm.$auth.user && _vm.$auth.setting && _vm.$auth.setting.client_wallet_status == 'active')?_c('router-link',{attrs:{"to":"/wallet"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var href = ref.href;
var navigate = ref.navigate;
var isActive = ref.isActive;
return [_c('li',{class:[ isActive ? 'nav-item active':'nav-item' ]},[_c('a',{staticClass:"nav-link",attrs:{"href":href},on:{"click":navigate}},[_vm._v(" "+_vm._s(_vm.$t('My Wallet'))+" "),_c('div',{staticClass:"wallet-bal"},[_c('span',{domProps:{"innerHTML":_vm._s(_vm.$auth.setting.currency)}}),_vm._v(_vm._s(_vm.mywalletAmount))])])])]}}],null,false,1164930065)}):_vm._e(),(_vm.$auth.user)?_c('router-link',{attrs:{"to":"/orders"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var href = ref.href;
var navigate = ref.navigate;
var isActive = ref.isActive;
return [_c('li',{class:[ isActive ? 'nav-item active':'nav-item' ]},[_c('a',{staticClass:"nav-link",attrs:{"href":href},on:{"click":navigate}},[_vm._v(" "+_vm._s(_vm.$t('Orders'))+" ")])])]}}],null,false,772920140)}):_vm._e(),_c('router-link',{attrs:{"to":"/promo-code"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var href = ref.href;
var navigate = ref.navigate;
var isActive = ref.isActive;
return [_c('li',{class:[ isActive ? 'nav-item active':'nav-item' ]},[_c('a',{staticClass:"nav-link",attrs:{"href":href},on:{"click":navigate}},[_vm._v(" "+_vm._s(_vm.$t('Promocode'))+" ")])])]}}])}),_c('router-link',{attrs:{"to":"/help"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var href = ref.href;
var navigate = ref.navigate;
var isActive = ref.isActive;
return [_c('li',{class:[ isActive ? 'nav-item active':'nav-item' ]},[_c('a',{staticClass:"nav-link",attrs:{"href":href},on:{"click":navigate}},[_vm._v(" "+_vm._s(_vm.$t('Help'))+" ")])])]}}])}),_c('router-link',{attrs:{"to":"/faq"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var href = ref.href;
var navigate = ref.navigate;
var isActive = ref.isActive;
return [_c('li',{class:[ isActive ? 'nav-item active':'nav-item' ]},[_c('a',{staticClass:"nav-link",attrs:{"href":href},on:{"click":navigate}},[_vm._v(" "+_vm._s(_vm.$t('FAQ'))+" ")])])]}}])}),(_vm.terms_url)?_c('router-link',{attrs:{"to":"/terms"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var href = ref.href;
var navigate = ref.navigate;
var isActive = ref.isActive;
return [_c('li',{class:[ isActive ? 'nav-item active':'nav-item' ]},[_c('a',{staticClass:"nav-link",attrs:{"href":href},on:{"click":navigate}},[_vm._v(" "+_vm._s(_vm.$t('Terms & conditions'))+" ")])])]}}],null,false,1931650858)}):_vm._e(),(_vm.privacypolicy_url)?_c('router-link',{attrs:{"to":"/privacypolicy"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var href = ref.href;
var navigate = ref.navigate;
var isActive = ref.isActive;
return [_c('li',{class:[ isActive ? 'nav-item active':'nav-item' ]},[_c('a',{staticClass:"nav-link",attrs:{"href":href},on:{"click":navigate}},[_vm._v(" "+_vm._s(_vm.$t('Privacy Policy'))+" ")])])]}}],null,false,1594858391)}):_vm._e(),(_vm.nosupport)?_c('router-link',{attrs:{"to":"/support"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var href = ref.href;
var navigate = ref.navigate;
var isActive = ref.isActive;
return [_c('li',{class:[ isActive ? 'nav-item active':'nav-item' ]},[_c('a',{staticClass:"nav-link",attrs:{"href":href},on:{"click":navigate}},[_vm._v(" "+_vm._s(_vm.$t('Support'))+" ")])])]}}],null,false,1248484414)}):_vm._e(),_c('li',{staticClass:"nav-item logout-nav",on:{"click":_vm.LogOut}},[(_vm.$auth.user)?_c('a',{staticClass:"nav-link"},[_vm._v(_vm._s(_vm.$t('Logout')))]):_vm._e()])],1),_c('div',{staticClass:"store-btns d-flex align-items-center mt-4"},[(_vm.$auth.setting && _vm.$auth.setting.ios_customer_app_url)?_c('a',{attrs:{"href":_vm.$auth.setting.ios_customer_app_url}},[_c('img',{attrs:{"src":require("@/assets/images/app-store.png")}})]):_vm._e(),(_vm.$auth.setting && _vm.$auth.setting.android_customer_app_url)?_c('a',{attrs:{"href":_vm.$auth.setting.android_customer_app_url}},[_c('img',{attrs:{"src":require("@/assets/images/google-play.png")}})]):_vm._e()])])}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('button',{staticClass:"navbar-toggler navbar-toggler-right close-btn border-0",attrs:{"type":"button","data-toggle":"collapse","data-target":"#navbarSupportedContent","aria-controls":"navbarSupportedContent","aria-expanded":"false","aria-label":"Toggle navigation"}},[_c('span',[_c('i',{staticClass:"fas fa-times"})])])}]

export { render, staticRenderFns }